<template>
  <v-card class="d-flex flex-column">
    <template #title>{{ $t(`${ticketType.toLowerCase()}s.create`) }}</template>

    <v-form
      v-model="valid"
      :loading="ticketAssetStatus === 'pending'"
      class="overflow-auto d-flex flex-column"
      @submit.prevent="() => onSubmit()"
    >
      <v-card-text class="overflow-auto">
        <v-select
          v-if="showInspectionTypes && !!formData.data"
          v-model="(formData as Inspection).data.inspectionType"
          style="margin-bottom: 6px"
          :loading="typesPending"
          :label="$t('inspections.field.inspectionType') + ' *'"
          :items="inspectionTypes!"
          item-title="description"
          item-value="description"
          :rules="[(val) => !!val || $t('validation.required')]"
        />

        <ticket-general-form v-model="formData" :ticket-type="ticketType" :show-asset-field="!assetId" />
      </v-card-text>

      <v-divider />

      <v-card-actions class="flex-wrap justify-end">
        <v-btn color="primary" @click="() => onReset()">{{ $t("cancel") }}</v-btn>
        <v-btn color="primary" :loading="saving" type="submit">{{ $t("save") }}</v-btn>
        <v-btn color="primary" :loading="saving" type="submit" @click="() => (redirectAfterSave = true)">{{ $t("saveAndOpen") }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "ok", newTicketId: string): void
  (e: "cancel"): void
}>()

const props = defineProps<{ assetId?: string; ticketType: TicketType; organizationId?: string }>()
const { assetId, organizationId, ticketType } = toRefs(props)

const $i18n = useI18n()
const $router = useRouter()

const mainStore = useMainStore()
const actionStore = useActionStore()
const inspectionStore = useInspectionStore()
const nen3140Store = useNen3140Store()
const malfunctionStore = useMalfunctionStore()
const authStore = useAuthStore()
const organizationStore = useOrganizationStore()
const ticketStore = useTicketStore()
const supplierStore = useSupplierStore()

const { currentOrganization, isSupplier, sourceOrganization } = storeToRefs(authStore)
const { isLoggedInOnSupplier } = storeToRefs(supplierStore)

const { data: inspectionTypes, pending: typesPending } = useLazyAsyncData(
  "inspection-types",
  async () => {
    if (ticketType.value !== TicketType.INSPECTION) return []

    return await inspectionStore.getTypes()
  },
  { default: () => [], watch: [ticketType] },
)

const { data: ticketAsset, status: ticketAssetStatus } = useLazyAsyncData(
  `asset-ticket-${assetId?.value}`,
  async () => {
    if (!assetId.value) {
      return undefined
    }

    return ticketStore.getTicketAssetById(assetId.value)
  },
  { default: () => undefined },
)

const valid = ref<boolean>()
const saving = ref(false)
const redirectAfterSave = ref(false)

const formData = ref<Partial<Ticket>>({
  type: ticketType.value,
  asset: ticketAsset.value,
  organization: organizationId?.value ?? (isLoggedInOnSupplier.value ? undefined : currentOrganization.value),
  status: StatusDescription.NEW,
  data: ticketType.value === TicketType.INSPECTION ? { inspectionType: "BRL" } : {},
} as Partial<Ticket>)

const showInspectionTypes = computed(
  () => ticketType.value === TicketType.INSPECTION && inspectionTypes.value?.filter((type) => type.description !== "BRL").length > 0,
)

const onSubmit = () => {
  if (!valid.value) {
    return
  }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    saving.value = true

    let newTicketId: string

    try {
      const generalTicketData = {
        asset: formData.value.asset?._id ?? assetId.value!,
        date: formData.value.date,
        description: formData.value.description!,
        supplier: formData.value.supplier?._id,
        users: formData.value.users?.map((user) => user._id) ?? [],
        contract: formData.value.contract?._id,
        remark: formData.value.remark,
        invoicePeriod: formData.value.invoicePeriod,
      }

      if (ticketType.value === TicketType.INSPECTION) {
        const inspection = await inspectionStore.createInspection({
          ...generalTicketData,
          data: formData.value.data as InspectionData,
        })
        newTicketId = inspection._id
      } else if (ticketType.value === TicketType.NEN3140 || ticketType.value === TicketType.NEN1010) {
        const inspection = await nen3140Store.create({
          ...generalTicketData,
          type: ticketType.value,
        })
        newTicketId = inspection._id
      } else if (ticketType.value === TicketType.ACTION) {
        const data = formData.value.data as ActionData | undefined
        const action = await actionStore.create({
          ...generalTicketData,
          startAt: formData.value.startAt,
          endAt: formData.value.endAt,
          data: {
            component: data?.component?._id,
            componentGroup: data?.componentGroup?._id,
            settlement: data?.settlement,
          },
        })

        newTicketId = action._id
      } else {
        const data = formData.value.data as MalfunctionData | undefined
        const malfunction = await malfunctionStore.create({
          ...generalTicketData,
          startAt: formData.value.startAt,
          endAt: formData.value.endAt,
          data: {
            component: data?.component?._id,
            componentGroup: data?.componentGroup?._id,
            cause: data?.cause?._id,
            solution: data?.solution?._id,
            settlement: data?.settlement,
          },
        })

        newTicketId = malfunction._id
      }
    } catch (error) {
      saving.value = false
      console.error(error)
      throw error
    }

    if (redirectAfterSave.value) {
      $router.push(`/tickets/${ticketType.value.toLowerCase()}s/${newTicketId}`)
    }

    saving.value = false
    emit("ok", newTicketId)
  })
}

const onReset = () => emit("cancel")

watch(
  [isSupplier, sourceOrganization],
  async () => {
    if (formData.value.supplier) {
      return
    }

    if (isSupplier.value && sourceOrganization.value) {
      const { _id, description } = await organizationStore.getOrganization(sourceOrganization.value)
      formData.value.supplier = { _id, description }
    }
  },
  { immediate: true },
)
</script>
